<template>
  <div class="p-4">
    <h1>404 Not Found</h1>
    <p>The page you're attempting to access does not exist. Please make sure your URL has no mistakes.</p>
  </div>
</template>
<script>
export default {
  name: "PageNotFound",
}
</script>

<style scoped>
  .p-4 {
    padding: 30px;
  }
</style>
